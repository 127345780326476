import { FormEvent, useContext, useEffect, useState } from 'react'
import adicionarIcon from '../../assets/img/botao-adicionar.png'
import editarIcon from '../../assets/img/editar.png'
import { adicionarPergunta, atualizarPergunta, listarPerguntas } from '../../services/BancoTalentos'
import { listaPerguntaPayload } from '../../interfaces/BancoTalentos'
import "./modalAvaliacao.css"
import { Button } from '../Styles'
import { criptografarDado } from '../../helpers'
import { ModalEstadoContext } from '../../contexts/ModalEstado'

function ModalAvaliacaoDisc() {

  const { modalExibir } = useContext(ModalEstadoContext)

  const [perguntas, setPerguntas] = useState<listaPerguntaPayload[]>([])
  const [pergunta, setPergunta] = useState("")
  const [a, setA] = useState("");
  const [b, setB] = useState("");
  const [c, setC] = useState("");
  const [d, setD] = useState("");
  const [situacao, setSituacao] = useState("");

  const [modalPerguntaDisc, setModalPerguntaDisc] = useState(false)
  const [modalNovaPerguntaDisc, setModalNovaPerguntaDisc] = useState(false)
  const [alternativaSelecionada, setAlternativaSelecionada] = useState<listaPerguntaPayload>()

  const listarPerguntasAvaliacao = async () => {
    try {
      const perguntas = await listarPerguntas()
      setPerguntas(perguntas.data)
    } catch (error) {
      modalExibir("erroListarPergunta")
      return setTimeout(() => {
        modalExibir("")
      }, 5000)
    }
  }

  const abrirModalEditarPergunta = (alternativa: listaPerguntaPayload) => {
    setAlternativaSelecionada(alternativa)
    setPergunta(alternativa.pergunta)
    setModalPerguntaDisc(true)
  }

  const fecharModalEditarPergunta = () => {
    setAlternativaSelecionada(undefined)
    setModalNovaPerguntaDisc(false)
    setModalPerguntaDisc(false)
  }

  const abrirModalAdicionarPergunta = () => {
    fecharModalEditarPergunta()
    setAlternativaSelecionada(undefined)
    setModalNovaPerguntaDisc(true)
    setModalPerguntaDisc(true)
  }

  const handleUpdate = async (e: FormEvent) => {
    e.preventDefault()
    try {
      if (!alternativaSelecionada) {
        modalExibir("erroAlternativa")
        return setTimeout(() => {
          modalExibir("")
        }, 5000)
      }

      const idPergunta = alternativaSelecionada.id
      const idAlternativa = alternativaSelecionada.discAlternativas[0].id

      const idPerguntaCriptografado = criptografarDado(idPergunta.toString())
      const idAlternativaCriptografado = criptografarDado(idAlternativa.toString())
      const payload = {
        pergunta,
        situacao,
        a, b, c, d
      }
      const payloadFiltrado = filtroPayload(payload)

      await atualizarPergunta(idPerguntaCriptografado, idAlternativaCriptografado, payloadFiltrado)
      window.location.reload()

    } catch (error) {
      modalExibir("erroAtualizarPergunta")
      return setTimeout(() => {
        modalExibir("")
      }, 5000)
    }
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    try {
      const payload = {
        pergunta,
        alternativas: { a, b, c, d }
      }

      const perguntaAtualizada = await adicionarPergunta(payload)
      if (perguntaAtualizada.status === 201) return window.location.reload()

    } catch (error) {
      modalExibir("erroAdicionarPergunta")
      return setTimeout(() => {
        modalExibir("")
      }, 5000)

    }
  }

  const filtroPayload = (payload: any) => {
    return Object.fromEntries(
      Object.entries(payload).filter(
        ([_, valor]) => valor !== null && valor !== undefined && valor !== ""
      )
    )
  }

  useEffect(() => {
    listarPerguntasAvaliacao()
  }, [])

  return (
    <div className='fundoModal'>
      <section className='containerModalCentro'>
        <div className='containerModalCandidato'>
          {!modalPerguntaDisc && <section>
            <span className="novoContainerTitulo">
              <h1>Avaliação Disc</h1>
              <img src={adicionarIcon} className='adicionarModalIcone' alt='' onClick={() => abrirModalAdicionarPergunta()} />
            </span>

            <ul className='formModalNovo'>
              {perguntas.map((alternativa) => (
                <li className='listaAvaliacao' key={alternativa.id}>
                  <span>
                    {alternativa.pergunta}
                  </span>
                  <p className={alternativa.situacao ? 'visivel' : 'oculto'}>
                    {alternativa.situacao && "Visível"}
                    {!alternativa.situacao && "Oculto"}
                  </p>
                  <img src={editarIcon} className='adicionarModalIcone' alt='' onClick={() => abrirModalEditarPergunta(alternativa)} />
                </li>
              ))}
            </ul>
            <span className="botoesContainer">
              <Button type='button' cor='cancelar' isLoading={false} onClick={() => modalExibir("")}>Fechar</Button>
            </span>
          </section>}
          {alternativaSelecionada &&
            <section>
              <span className="novoContainerTitulo">
                <h1>Avaliação Disc</h1>
                <img src={adicionarIcon} className='adicionarModalIcone' alt='' onClick={() => abrirModalAdicionarPergunta()} />
              </span>
              <form className='formModalNovo' onSubmit={handleUpdate}>

                <section>
                  <span>
                    <label htmlFor="">Pergunta</label>
                    <input type="text" defaultValue={alternativaSelecionada.pergunta} onChange={(e: any) => setPergunta(e.target.value)} required />
                  </span>
                </section>
                <section>
                  <span>
                    <label htmlFor="">Alternativa A</label>
                    <input type="text" defaultValue={alternativaSelecionada.discAlternativas[0].a} onChange={(e: any) => setA(e.target.value)} required />
                  </span>
                  <span>
                    <label htmlFor="">Alternativa B</label>
                    <input type="text" defaultValue={alternativaSelecionada.discAlternativas[0].b} onChange={(e: any) => setB(e.target.value)} required />
                  </span>
                </section>
                <section>
                  <span>
                    <label htmlFor="">Alternativa C</label>
                    <input type="text" defaultValue={alternativaSelecionada.discAlternativas[0].c} onChange={(e: any) => setC(e.target.value)} required />
                  </span>
                  <span>
                    <label htmlFor="">Alternativa D</label>
                    <input type="text" defaultValue={alternativaSelecionada.discAlternativas[0].d} onChange={(e: any) => setD(e.target.value)} required />
                  </span>
                </section>
                <section>
                  <span>
                    <label htmlFor="">Tornar Pergunta Visivel?</label>
                    <select defaultValue={alternativaSelecionada.situacao ? "true" : "false"} onChange={(e) => setSituacao(e.target.value)}>
                      <option value="true">Sim</option>
                      <option value="false">Não</option>
                    </select>
                  </span>
                  <span></span>
                </section>
                <span className="botoesContainer">
                  <Button type='button' cor='cancelar' isLoading={false} onClick={() => fecharModalEditarPergunta()}>Voltar</Button>
                  <Button type='submit' cor='enviar' isLoading={false}>Salvar</Button>
                </span>
              </form>
            </section>
          }
          {modalNovaPerguntaDisc &&
            <section>
              <span className="novoContainerTitulo">
                <h1>Avaliação Disc</h1>
                <img src={adicionarIcon} className='adicionarModalIcone' alt='' onClick={() => abrirModalAdicionarPergunta()} />
              </span>
              <form className='formModalNovo' onSubmit={handleSubmit}>
                <section>
                  <span>
                    <label htmlFor="">Pergunta</label>
                    <input type="text" onChange={(e: any) => setPergunta(e.target.value)} placeholder='Insira uma nova pergunta' required />
                  </span>
                </section>
                <section>
                  <span>
                    <label htmlFor="">Alternativa A</label>
                    <input onChange={(e: any) => setA(e.target.value)} placeholder='Insira uma alternativa' required />
                  </span>
                  <span>
                    <label htmlFor="">Alternativa B</label>
                    <input onChange={(e: any) => setB(e.target.value)} placeholder='Insira uma alternativa' required />
                  </span>
                </section>
                <section>
                  <span>
                    <label htmlFor="">Alternativa C</label>
                    <input onChange={(e: any) => setC(e.target.value)} placeholder='Insira uma alternativa' required />
                  </span>
                  <span>
                    <label htmlFor="">Alternativa D</label>
                    <input onChange={(e: any) => setD(e.target.value)} placeholder='Insira uma alternativa' required />
                  </span>
                </section>
                <span className="botoesContainer">
                  <Button type='button' cor='cancelar' isLoading={false} onClick={() => fecharModalEditarPergunta()}>Voltar</Button>
                  <Button type='submit' cor='enviar' isLoading={false}>Salvar</Button>
                </span>
              </form>
            </section>
          }
        </div>
      </section>
    </div>
  )
}

export default ModalAvaliacaoDisc